import React, { useEffect, useState } from 'react';
import { instanceStructures } from './constants';
import '../Style/TradeLog.scss'; // Import the SCSS file
import { exitWatch } from '../utils/apis';
import Spinner from '../../utils/Spinner/Spinner';

const TradeLog = ({ activeOption }) => {
  const [instances, setInstances] = useState([]);
  const [loadingState, setLoadingState] = useState({}); // Object to track loading for each instance

  useEffect(() => {
    const ws = new WebSocket(`wss://pigeoncoops.tech/ws/engine/user/strategy/stream?wid=${activeOption}`);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const validatedData = data.data.map(instance => ({
        ...instanceStructures[activeOption],
        ...instance
      }));

      setInstances(validatedData);
      console.log(validatedData)
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    ws.onclose = () => {
      setInstances([]);
      console.log('WebSocket connection closed');
    };

    return () => {
      ws.close();
    };
  }, [activeOption]);

  const handleExit = async (id) => {
    setLoadingState(prev => ({ ...prev, [id]: true })); // Set loading true for the specific instance
    console.log(`Exit instance with id: ${id}`);
    const payload = {
      strategy_id: activeOption,
      watch_id: id
    };
    console.log(payload);

    await exitWatch(payload); // Wait for the exit API to complete

    setLoadingState(prev => ({ ...prev, [id]: false })); // Reset loading for the specific instance
  };

  return (
    <div className="trade-log-container">
      {instances.map((instance) => (
        <div key={instance.id} className="trade-log-table-container">
          <table className="trade-log-table">
            <thead>
              <tr>
                <th>Ticker</th>
                <th>Type</th>
                <th>Entry Time</th>
                <th>Entry Price</th>
                <th>Curr Price</th>
                <th>Order Status</th>
                {activeOption === 'VT_RE' && <th>Re-Entry Number</th>}
                <th>Curr PnL</th>
                <th>Total PnL</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <React.Fragment>
                <tr>
                  <td>{instance.CE?.ticker}</td>
                  <td>CE</td>
                  <td>{instance.CE?.entry_time}</td>
                  <td>{instance.CE?.entry_price}</td>
                  <td>{instance.CE?.curr_price}</td>
                  <td>{instance.CE?.status}</td>
                  {activeOption === "VT_RE" && <td>{instance?.CE.entry_no}</td>}
                  <td>{instance.CE?.curr_pnl}</td>
                  <td rowSpan="2">{instance.total_pnl}</td>
                  <td rowSpan="2">
                    {!instance?.exit_flg && (loadingState[instance.id] ? (
                      <Spinner />
                    ) : (
                      <button
                        onClick={() => handleExit(instance.id)}
                        disabled={loadingState[instance.id]} // Disable only the specific button
                      >
                        Exit
                      </button>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td>{instance.PE?.ticker}</td>
                  <td>PE</td>
                  <td>{instance.PE?.entry_time}</td>
                  <td>{instance.PE?.entry_price}</td>
                  <td>{instance.PE?.curr_price}</td>
                  <td>{instance.PE?.status}</td>
                  {activeOption === "VT_RE" && <td>{instance?.PE.entry_no}</td>}
                  <td>{instance.PE?.curr_pnl}</td>
                </tr>
              </React.Fragment>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default TradeLog;
