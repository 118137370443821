const symbolOptions = ['NIFTY', 'BANKNIFTY', 'MIDCPNIFTY', 'SENSEX', 'FINNIFTY']
const reEntryParamsList = [
  { type: 'number', name: 'lot', label: 'Quantity', placeholder: 'Quantity' },
  { type: 'number', step: '0.01', name: 'om_percent', label: 'OM Percentage', placeholder: 'OM Percentage' },
  { type: 'number', step: '0.01', name: 'stoploss', label: 'Stoploss Percentage', placeholder: 'Stoploss Percentage' },
  { type: 'number', name: 'no_of_reentries', label: 'No of Re-entry', placeholder: 'No of Re-entry' },
  { type: 'select', name: 'symbol', label: 'Symbol', options: symbolOptions },
];

const deepOmParamsList = [
  { type: 'number', name: 'lot', label: 'Quantity', placeholder: 'Quantity' },
  { type: 'number', step: '0.01', name: 'om_percent', label: 'OM Percentage', placeholder: 'OM Percentage' },
  { type: 'number', step: '0.01', name: 'stoploss', label: 'Stoploss Percentage', placeholder: 'Stoploss Percentage' },
  { type: 'select', name: 'symbol', label: 'Symbol', options: symbolOptions },
];

export const inputParams = {
  'VT_RE': reEntryParamsList,
  'VT_DOM': deepOmParamsList,
};

export const instanceStructures = {
  'VT_RE': {
    CE: {
      ticker: '',
      entry_time: '',
      entry_price: 0.0,
      curr_price: 0.0,
      status: '',
      curr_pnl: 0.0,
      entry_no: 0.0
    },
    PE: {
      ticker: 0,
      entry_time: '',
      entry_price: 0.0,
      curr_price: 0.0,
      status: '',
      curr_pnl: 0.0,
      entry_no: 0.0
    },
    total_pnl: 0.0
  },
  'VT_DOM': {
    CE: {
      Ticker: "",
      entry_time: '',
      entry_price: 0.0,
      curr_price: 0.0,
      status: '',
      curr_pnl: 0.0
    },
    PE: {
      Ticker: "",
      entry_time: "",
      entry_price: 0.0,
      curr_price: 0.0,
      status: '',
      curr_pnl: 0.0
    },
    total_pnl: 0
  }
};

export const API_URLS = {
  INSERT_WATCH: '/engine/user/strategy/insert/watch',
  EXIT_WATCH: '/engine/user/strategy/exit/watch'
};