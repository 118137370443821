import React, { useState } from 'react';

import InputField from './InputField';
import TradeLog from './TradeLog';
import { inputParams } from './constants';

import '../Style/Dashboard.scss'; // Import the SCSS file
import { insertWatchData } from '../utils/apis';
import Spinner from '../../utils/Spinner/Spinner';

const Dashboard = () => {
  const [activeOption, setActiveOption] = useState('VT_RE');
  const [formData, setFormData] = useState({
    'VT_RE': {
      lot: 0,
      om_percent: 0,
      stoploss: 0,
      no_of_reentries: 0,
      symbol: ''
    },
    'VT_DOM': {
      lot: 0,
      om_percent: 0,
      stoploss: 0,
      symbol: ''
    }
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [activeOption]: {
        ...prevData[activeOption],
        [name]: value
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    console.log(loading)
    const lot = parseFloat(formData[activeOption].lot);
    const om_percent = parseFloat(formData[activeOption].om_percent);
    const stoploss = parseFloat(formData[activeOption].stoploss);
    const no_of_reentry = parseInt(formData[activeOption].no_of_reentries, 10);
    const payload = {
      strategy_id: activeOption,
      watch_data: {
        lot: isNaN(lot) ? 0 : lot,
        om_percent: isNaN(om_percent) ? 0 : om_percent,
        stoploss: isNaN(stoploss) ? 0 : stoploss,
        no_of_reentries: isNaN(no_of_reentry) ? 0 : no_of_reentry,
        symbol: formData[activeOption].symbol,
        open_hedge_orders: false,
        order_type: 'MARKET',
        product_type: 'MIS'
      }
    };
    await insertWatchData(payload)
    setLoading(false)
    console.log(loading)
  };


  return (
    <div className="dashboard-container">
      <div className="options-container">
        <button
          className={`option-button ${activeOption === 'VT_RE' ? 'active' : ''}`}
          onClick={() => setActiveOption('VT_RE')}
        >
          Re-entry
        </button>
        <button
          className={`option-button ${activeOption === 'VT_DOM' ? 'active' : ''}`}
          onClick={() => setActiveOption('VT_DOM')}
        >
          Deep-OM
        </button>
      </div>

      <div className="form-and-log">
        <div className="input-container">
          <form onSubmit={handleSubmit}>
            {inputParams[activeOption].map((param, index) => (
              <InputField
                key={index}
                type={param.type}
                name={param.name}
                label={param.label}
                placeholder={param.placeholder}
                step={param.step}
                options={param.options}
                value={formData[activeOption][param.name]}
                onChange={handleChange}
              />
            ))}
            {loading ? (
              <Spinner />
            ) : (
              <button disabled={loading} onClick={(e) => handleSubmit(e)}>Submit</button>
            )}

          </form>
        </div>

        <div className="table-container">
          <TradeLog activeOption={activeOption} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
