import React, { useState } from 'react';
import { apiCall } from '../utils/apis';
import { useNavigate } from 'react-router-dom';
import '../Style/Login.scss'; // Import your SCSS file
import Spinner from '../../utils/Spinner/Spinner';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // New loading state
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when login starts

    const payload = new URLSearchParams({
      grant_type: 'password',
      username: username,
      password: password,
      scope: '',
      client_id: 'string',
      client_secret: 'string'
    }).toString();

    try {
      const data = await apiCall({
        method: 'POST',
        endpoint: '/engine/auth/jwt/login',
        payload,
      });

      const token = data.access_token;
      const tokenExpiry = Date.now() + 6 * 60 * 60 * 1000; // 6 hours from now

      // Store token and expiration time in sessionStorage
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('tokenExpiry', tokenExpiry);

      navigate('/dashboard');
    } catch (error) {
      setError(error.message || 'Login failed');
    } finally {
      setLoading(false); // Reset loading to false after login completes
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <h2>Login</h2>
        <div>
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            disabled={loading} // Disable input during loading
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading} // Disable input during loading
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        {loading ? (
          <Spinner />
        ) : (
          <button type="submit">Login</button>
        )}
      </form>
    </div>
  );
};

export default Login;
