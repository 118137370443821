import { API_URLS } from "../Components/constants";

const BASE_URL = 'https://pigeoncoops.tech/api';

export const apiCall = async ({ method = '', endpoint = '', params = {}, payload = null }) => {
  // Construct query string for GET requests with params
  const queryString = new URLSearchParams(params).toString();
  const url = `${BASE_URL}${endpoint}${queryString ? `?${queryString}` : ''}`;
  const token = sessionStorage.getItem('token');

  try {
    const response = await fetch(url, {
      method,
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` }),
      },
      body: method !== 'GET' && payload ? JSON.stringify(payload) : null
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `API Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const insertWatchData = async (payload) => {
  try {
    const data = await apiCall({
      method: 'POST',
      endpoint: API_URLS.INSERT_WATCH,
      payload: payload
    });
    console.log('Fetched Data:', data);
    alert(data.data);
  } catch (error) {
    console.log(error.message)
    if (error.message) {
      alert(error.message);
    } else {
      alert('An unexpected error occurred.');
    }
    console.error('API Call Failed:', error);
  }
};

export const exitWatch = async (payload) => {
  try {
    const data = await apiCall({
      method: 'POST',
      endpoint: API_URLS.EXIT_WATCH,
      payload: payload
    });
    console.log('Fetched Data:', data);
    alert("Exit successful")
  } catch (error) {
    if (error.message) {
      alert(error.message);
    } else {
      alert('An unexpected error occurred.');
    }
    console.error('API Call Failed:', error);
  }
};
