const BASE_URL = 'https://pigeoncoops.tech/api';

export const apiCall = async ({ method = '', endpoint = '', params = {}, payload = null }) => {
  // Construct query string for GET requests with params
  const queryString = new URLSearchParams(params).toString();
  const url = `${BASE_URL}${endpoint}${queryString ? `?${queryString}` : ''}`;

  try {
    const response = await fetch(url, {
      method,
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: method !== 'GET' && payload ? payload : null
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `API Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};