import React from 'react';

const InputField = ({ type, name, label, placeholder, step, value, onChange, options }) => {
  if (type === 'select') {
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <select
          name={name}
          id={name}
          value={value || ''}
          onChange={onChange}
        >
          <option value="" disabled>Select an option</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        step={step}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
