import React from 'react';
import { Navigate } from 'react-router-dom';

// Utility function to check if the token is expired
const isTokenExpired = () => {
  const tokenExpiry = sessionStorage.getItem('tokenExpiry');
  return !tokenExpiry || Date.now() > parseInt(tokenExpiry, 10);
};

const isAuthenticated = () => {
  const token = sessionStorage.getItem('token');
  return !!token && !isTokenExpired(); // Check both token existence and expiration
};

const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;